<template>
  <div class="color-picker">
    <h2 class="title">
      {{$t('components.colorPicker.selectColor')}}
    </h2>
    <div :style="colorListStyle()"
         class="color-list">
      <span v-for="(color, idx) in colors"
            :key="idx"
            :style="colorItemStyle(color)"
            class="color-item"
            @mouseover="emitHoveredColor(color)"
            @mouseout="emitHoveredColor(null)"
            @click="emitClickedColor(color)" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ColorPicker',
  props: {
    width: {
      type: String,
      default: 'auto',
    },
  },
  computed: {
    ...mapState('ui', {
      colors: state => state.colorPicker,
    }),
  },
  methods: {
    colorListStyle() {
      return {
        width: `${this.width}`,
      };
    },
    colorItemStyle(color) {
      return {
        'background-color': color,
      };
    },
    emitHoveredColor(color) {
      this.$emit('hover', color);
    },
    emitClickedColor(color) {
      this.$emit('click', color);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.color-picker
  padding 8px
  .title
    margin 0
    margin-bottom 8px
    font-weight 700
    font-size $fs-h3
    line-height 1
  .color-list
    display flex
    height 70px
    background-color blue
    .color-item
      flex 1
      height 100%
      cursor pointer
      transition transform 0.2s ease
      &:hover
        transform scale(1.2)
</style>
