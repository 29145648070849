class XY {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

const hueLimits = {
  red: new XY(0.675, 0.322),
  green: new XY(0.4091, 0.518),
  blue: new XY(0.167, 0.04),
};

export default {
  methods: {
    _crossProduct(p1, p2) {
      return p1.x * p2.y - p1.y * p2.x;
    },
    _isInColorGamut(p, lampLimits) {
      const v1 = new XY(lampLimits.green.x - lampLimits.red.x, lampLimits.green.y - lampLimits.red.y);
      const v2 = new XY(lampLimits.blue.x - lampLimits.red.x, lampLimits.blue.y - lampLimits.red.y);
      const q = new XY(p.x - lampLimits.red.x, p.y - lampLimits.red.y);
      const s = this._crossProduct(q, v2) / this._crossProduct(v1, v2);
      const t = this._crossProduct(v1, q) / this._crossProduct(v1, v2);

      return s >= 0.0 && t >= 0.0 && s + t <= 1.0;
    },
    _resolveXYPointForLamp(point, limits) {
      const pAB = this._getClosestPoint(limits.red, limits.green, point);
      const pAC = this._getClosestPoint(limits.blue, limits.red, point);
      const pBC = this._getClosestPoint(limits.green, limits.blue, point);
      const dAB = this._getDistanceBetweenPoints(point, pAB);
      const dAC = this._getDistanceBetweenPoints(point, pAC);
      const dBC = this._getDistanceBetweenPoints(point, pBC);
      let lowest = dAB;
      let closestPoint = pAB;

      if (dAC < lowest) {
        lowest = dAC;
        closestPoint = pAC;
      }

      if (dBC < lowest) {
        closestPoint = pBC;
      }

      return closestPoint;
    },
    _getClosestPoint(start, stop, point) {
      const AP = new XY(point.x - start.x, point.y - start.y);
      const AB = new XY(stop.x - start.x, stop.y - start.y);
      const ab2 = AB.x * AB.x + AB.y * AB.y;
      const ap_ab = AP.x * AB.x + AP.y * AB.y;
      let t = ap_ab / ab2;

      if (t < 0.0) {
        t = 0.0;
      } else if (t > 1.0) {
        t = 1.0;
      }

      return new XY(start.x + AB.x * t, start.y + AB.y * t);
    },

    _getDistanceBetweenPoints(pOne, pTwo) {
      const dx = pOne.x - pTwo.x;
      const dy = pOne.y - pTwo.y;
      return Math.sqrt(dx * dx + dy * dy);
    },
    _getXYStateFromRGB(red, green, blue, limits) {
      const r = this._gammaCorrection(red);
      const g = this._gammaCorrection(green);
      const b = this._gammaCorrection(blue);
      const X = r * 0.4360747 + g * 0.3850649 + b * 0.0930804;
      const Y = r * 0.2225045 + g * 0.7168786 + b * 0.0406169;
      const Z = r * 0.0139322 + g * 0.0971045 + b * 0.7141733;
      let cx = X / (X + Y + Z);
      let cy = Y / (X + Y + Z);
      let xyPoint = undefined;

      cx = isNaN(cx) ? 0.0 : cx;
      cy = isNaN(cy) ? 0.0 : cy;

      xyPoint = new XY(cx, cy);

      if (!this._isInColorGamut(xyPoint, limits)) {
        xyPoint = this._resolveXYPointForLamp(xyPoint, limits);
      }

      return [xyPoint.x, xyPoint.y];
    },
    _gammaCorrection(value) {
      let result = value;
      if (value > 0.04045) {
        result = Math.pow((value + 0.055) / (1.0 + 0.055), 2.4);
      } else {
        result = value / 12.92;
      }
      return result;
    },
    convertRGBtoXY: function(r, g, b) {
      return this._getXYStateFromRGB(r, g, b, hueLimits);
    },
    convertXYtoRGB(x, y, brightness) {
      const Y = brightness;
      const X = (Y / y) * x;
      const Z = (Y / y) * (1 - x - y);
      let rgb = [X * 1.612 - Y * 0.203 - Z * 0.302, -X * 0.509 + Y * 1.412 + Z * 0.066, X * 0.026 - Y * 0.072 + Z * 0.962];

      // Apply reverse gamma correction.
      rgb = rgb.map(x => (x <= 0.0031308 ? 12.92 * x : (1.0 + 0.055) * Math.pow(x, 1.0 / 2.4) - 0.055));

      // Bring all negative components to zero.
      rgb = rgb.map(x => Math.max(0, x));

      // If one component is greater than 1, weight components by that value.
      const max = Math.max(rgb[0], rgb[1], rgb[2]);
      if (max > 1) {
        rgb = rgb.map(x => x / max);
      }

      rgb = rgb.map(x => Math.floor(x * 255));
      return rgb;
    },
  },
};
