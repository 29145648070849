<template>
  <div class="dashboard">
    <div class="data">
      <ColorPicker v-if="true"
                   class="color-picker"
                   @hover="showHoveredColor"
                   @click="setClickedColor" />
      <div class="row data__row">
        <q-btn round
               size="lg"
               @click="switchLightOn">
          <q-icon class="lightbulb-enable"
                  name="mdi-lightbulb-on"
                  size="30px" />
        </q-btn>
        <q-btn round
               size="lg"
               @click="switchLightOff">
          <q-icon class="lightbulb-disable"
                  name="mdi-lightbulb"
                  size="30px" />
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from '@/app/components/inputs/color-picker.vue';
import colorConverter from './colorConverter.js';

export default {
  name: 'PhilipsHueDashboard',
  components: { ColorPicker },
  mixins: [colorConverter],
  props: {
    sidebarExpanded: {
      type: Boolean,
      default: false,
    },
    global: {
      type: Object,
      default: null,
    },
    values: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      lights: [],
      creatingData: false,
      groupByName: false,
    };
  },
  computed: {
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    dataClass() {
      return this.sidebarExpanded ? 'data__label col-1' : 'data__label col-5';
    },
  },
  watch: {
    values: {
      handler(val) {
        this.initData();
      },
      deep: true,
    },
    'currentOapp.id'(val) {
      if (val && !val.startsWith('philips-hue')) {
        this.selectedNetwork = '';
      }
    },
    selectedBuilding(val) {
      if (val && !this.creatingData && this.selectedNetwork) this.fillCanvas();
    },
    groupByName(val) {
      if (!this.creatingData && this.selectedNetwork) this.fillCanvas();
    },
  },
  methods: {
    emitUpdateColor() {
      this.setPhilipsHueColor();
    },
    capitalize(s) {
      if (!s || s.length === 0) return s;
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    initData() {
      if (this.values && Object.keys(this.values).length) {
        Object.keys(this.currentOapp.data.values.spaces).forEach(spaceId => {
          this.currentOapp.data.values.spaces[spaceId].forEach(lightPlug => {
            const rgb = this.convertXYtoRGB(lightPlug.light.state.xy[0], lightPlug.light.state.xy[1], lightPlug.light.state.bri);
            lightPlug.light.state.hex = this.rgbToHex(rgb[0], rgb[1], rgb[2]);
          });
        });
      } else {
        this.lights = [];
      }
    },
    fillCanvas() {},
    showHoveredColor(color) {
      this.hoveredColor = color;
    },
    async setClickedColor(color) {
      const r = this.hexToR(color);
      const g = this.hexToG(color);
      const b = this.hexToB(color);
      const xy = this.convertRGBtoXY(r, g, b);
      await this.$store.dispatch('oapps/putCurrentOappData', { xy });
      this.$store.dispatch('oapp/fetchCurrentOappData');
    },
    switchLightOn() {
      this.$store.dispatch('oapps/putCurrentOappData', { on: true });
    },
    switchLightOff() {
      this.$store.dispatch('oapps/putCurrentOappData', { on: false });
    },
    hexToR(h) {
      return parseInt(this.cutHex(h).substring(0, 2), 16);
    },
    hexToG(h) {
      return parseInt(this.cutHex(h).substring(2, 4), 16);
    },
    hexToB(h) {
      return parseInt(this.cutHex(h).substring(4, 6), 16);
    },
    cutHex(h) {
      return h.charAt(0) === '#' ? h.substring(1, 7) : h;
    },
    rgbToHex(R, G, B) {
      return this.toHex(R) + this.toHex(G) + this.toHex(B);
    },
    toHex(n) {
      n = parseInt(n, 10);
      if (isNaN(n)) return '00';
      n = Math.max(0, Math.min(n, 255));
      return '0123456789ABCDEF'.charAt((n - (n % 16)) / 16) + '0123456789ABCDEF'.charAt(n % 16);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.dashboard
  position relative
  padding-top 15px
  .lightbulb-enable
    color #CCCA14
    &:hover
      cursor pointer
      transition all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)
  .lightbulb-disable
    color $grey
  .select-field
    position absolute
    top 8px
    left 10px
    font-weight 700
    font-size $fs-body
  .group-by-button
    margin 15px 0 0 10px
    font-size $fs-body
  .data
    padding 20px
    .data__row
      display flex
      flex-direction row
      justify-content space-evenly
    .data__label
      font-weight 700
      font-size $fs-h3
    .data__value
      font-size $fs-h3
  .graph-card
    margin-bottom $border-thick
    max-width 400px
    width 100%
    &:last-child
      margin-bottom 0
  .graph-card
    max-width 2000px
</style>
